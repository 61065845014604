<template>
  <div class="401-container">
    <errorPage
      :title="$t('errorPages.title401')"
      :headline="$t('errorPages.headline401')"
      :message="$t('errorPages.message401')"
    />
  </div>
</template>

<script>
import errorPage from './errorPage.vue';

export default {
  name: 'Page401',
  components: {
    errorPage
  },
  data() {
    return {};
  }
};
</script>
